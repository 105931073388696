import Layout from '@/components/Layout'
import Head from 'next/head'
import Link from 'next/link'

export default function FourOhFour() {
  return(
    <Layout pageTitle='Rapidé - Page Not Found'>
        <div className="relative top-0 max-w-full mx-auto lg:mt-0 md:mt-0 sm:mt-[93px] mt-[93px]">
            <div className="">
                <div className="relative bg-cover bg-no-repeat bg-fixed sm:bg-center bg-[center_top_4.84rem] bg-[url('/assets/images/be-a-partner-bg.webp')] lg:h-[550px] md:h-[450px] h-[200px]">                        
                    <div className='absolute sm:bottom-20 bottom-5 lg:left-28 left-5 bg-black/75 lg:w-5/12 sm:w-5/6 w-11/12'>
                        <div className='absolute top-16 -left-20 bg-rapide-yellow h-1 w-28'></div>
                        <h1 className='text-white tracking-widest pl-12 pb-4 sm:pt-12 pt-4 capitalize font-bold text-4xl'>Page Not Found</h1>
                        <div className='px-12 sm:pb-12 pb-4'>
                            <Link className='text-white tracking-widest capitalize cursor-pointer bg-rapide-red py-2 sm:px-4 px-2 rounded-md whitespace-nowrap' href="/">
                                Go back to homepage
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}